<template>
  <div class="app-container">
    <div class="filter-container">
      <el-button class="filter-item" type="primary" @click="handleCreate">新增</el-button>
      <el-input v-model="listQuery.name" placeholder="请输入院系" style="width: 200px;" class="filter-item" clearable/>
      <el-select v-model="listQuery.is_on" placeholder="状态" clearable style="width: 150px" class="filter-item">
        <el-option label="正常" :value="1"/>
        <el-option label="已下架" :value="0"/>
      </el-select>
      <el-button class="filter-item" type="primary" @click="handleFilter">搜索</el-button>
    </div>
    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="Loading"
      border
      fit
      highlight-current-row>
      <el-table-column label="序号" width="95" align="center">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column label="院系" min-width="110" align="center">
        <template slot-scope="scope">
          {{scope.row.name}}
        </template>
      </el-table-column>
      <el-table-column label="状态" min-width="110" align="center">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.is_on == 1" type="success">正常</el-tag>
          <el-tag v-else type="danger">已下架</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="200" align="center" class-name="small-padding fixed-width" fixed="right">
        <template slot-scope="scope">
          <el-button size="mini" type="primary" @click="handleUpdate(scope.row)">修改</el-button>
          <el-button v-if="scope.row.is_on == 1" size="mini" type="danger" @click="handleStatus(scope.row.id, '下架')">下架</el-button>
          <el-button v-else size="mini" type="success" @click="handleStatus(scope.row.id, '上架')">上架</el-button>
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination :current-page="listQuery.page" :page-sizes="[10,20,30, 50]" :page-size="listQuery.limit" :total="total" background layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChange" @current-change="handleCurrentChange"/>
    </div>

    <el-dialog :title="textMap[dialogStatus]" :visible.sync="dialogFormVisible" :close-on-click-modal="false">
      <el-form ref="form" :rules="rules" :model="form" label-width="120px">
        <el-form-item label="请输入院系" prop="name">
          <el-input type="text" v-model="form.name" clearable />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取消</el-button>
        <el-button type="primary" @click="saveData" :loading="btnLoading">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>

  import request from '@/utils/request'

  export default {
    data() {
      return {
        list: null,
        total: null,
        listLoading: true,
        listQuery: {
          page: 1,
          limit: 10,
          name: '',
          is_on: '',
        },
        dialogFormVisible: false,
        dialogStatus: '',
        textMap: {
          update: '修改',
          create: '新增'
        },
        form: {
          id: '',
          name: '',
        },
        rules: {
          name: [{ required: true, message: '院系不能为空', trigger: 'change' }],
        },
        btnLoading: false,
      }
    },
    created() {
      this.getList()
    },
    methods: {
      getList() {
        this.listLoading = true
        request({
          url: '/api/schoolend/school/collegeList',
          method: 'get',
          params: this.listQuery
        }).then(response => {
          this.list = response.data.data
          this.total = response.data.total
          this.listLoading = false
        })
      },
      handleFilter() {
        this.listQuery.page = 1
        this.getList()
      },
      handleSizeChange(val) {
        this.listQuery.limit = val
        this.getList()
      },
      handleCurrentChange(val) {
        this.listQuery.page = val
        this.getList()
      },
      resetForm() {
        this.form = {
          id: '',
          name: '',
        }
      },
      handleCreate() {
        this.resetForm()
        this.dialogStatus = 'create'
        this.dialogFormVisible = true
        this.$nextTick(() => {
          this.$refs['form'].clearValidate()
        })
      },
      handleUpdate(row) {
        this.resetForm()
        this.form = Object.assign({}, row)
        this.dialogStatus = 'update'
        this.dialogFormVisible = true
        this.$nextTick(() => {
          this.$refs['form'].clearValidate()
        })
      },
      saveData() {
        this.$refs['form'].validate((valid) => {
          if (valid) {
            this.btnLoading = true
            request({
              url: '/api/schoolend/school/collegeStore',
              method: 'post',
              data: this.form
            }).then(response => {
              this.btnLoading = false
              this.dialogFormVisible = false
              this.$message({
                type: 'success',
                message: '操作成功'
              });
              this.getList();
            }).catch((err) => {
              this.btnLoading = false
            })
          }
        })
      },
      //上下架
      handleStatus(val,text) {
        this.$confirm('此操作将'+text+'该院系, 是否继续?', '提示', {
          type: 'warning'
        }).then(() => {
          request({
            url: '/api/schoolend/school/collegeUpDown',
            method: 'post',
            data: {id: val}
          }).then(()=>{
            this.getList()
            this.$message({
              type: 'success',
              message: '操作成功'
            });
          })
        }).catch(() => {});
      },
    }
  }
</script>

<style rel="stylesheet/scss" lang="scss" scoped>

</style>
